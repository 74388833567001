import {
  DIGITALIZATION_CARGANDO,
  DIGITALIZATION_CHANGE_STATE,
  DIGITALIZATION_CLEAN_STATE,
  DIGITALIZATION_ERROR,
  DIGITALIZATION_LIST,
  DIGITALIZATION_TRANSFER,
} from "./digitalizationTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    message_loading: "",
    error: [],
    isActionSuccess: false,
  },
  list_supliers: [],
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DIGITALIZATION_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: [],
        },
      };
    case DIGITALIZATION_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
          isActionSuccess: false,
        },
      };

    case DIGITALIZATION_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: [],
        },
      };
    case DIGITALIZATION_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    case DIGITALIZATION_TRANSFER:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: [],
          isActionSuccess: true,
        },
        digitalization_action_success: action.payload.message,
      };
    case DIGITALIZATION_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: [],
        },
        list_supliers: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Reducer;
