import React from "react";
import { RedirectSelects } from "../../components-integrity";
import { Container } from "reactstrap";

export const GuruV1Module = () => {
  RedirectSelects();
  const iframeUrl = `${process.env.REACT_APP_ROUTE_CHAT_GURU_V1}?value=v1`;

  return (
    <div>
      <div style={{ marginTop: "45px" }}>
        <Container className="">
          <p
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              color: "#d2d3d3",
              fontfFamily: "'Ubuntu', sans-serif !important",
            }}>
            Bienvenid@ a la versión 1.0 de Guru...
          </p>
        </Container>
      </div>
      <iframe
        src={iframeUrl}
        title="Guru v1"
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          height: "65%",
          borderWidth: 0,
        }}
      />
    </div>
  );
};

export const GuruV2Module = () => {
  RedirectSelects();
  const iframeUrl = `${process.env.REACT_APP_ROUTE_CHAT_GURU_V1}?value=v2`;

  return (
    <div>
      <div style={{ marginTop: "45px" }}>
        <Container className="">
          <p
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              color: "#d2d3d3",
              fontfFamily: "'Ubuntu', sans-serif !important",
            }}>
            Bienvenid@ a la versión 2.0 de Guru...
          </p>
        </Container>
      </div>
      <iframe
        src={iframeUrl}
        title="Guru v1"
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          height: "65%",
          borderWidth: 0,
        }}
      />
    </div>
  );
};
