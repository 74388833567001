import React from "react";
import { Link } from "react-router-dom";
import { MyImage, MyP, SpecialContainer } from "./StyledComponents";
import styled from "styled-components";
import { useWindowSize } from "./hooks";

// Imagenes.
import SplashImg from "./assets/images/splash.png";
import LogoToyotaImg from "./assets/images/toyota-logo.png";
import LogoIntegritySvg from "./assets/svgs/logo-integrity.svg";
import PersonImg from "./assets/images/person.jpg";
import CafeteriaImg from "./assets/images/cafeteria.jpg";
import HeroWorkersImg from "./assets/images/hero-workers.png";

// Svgs.
import SvgClouds from "./assets/svgs/clouds.svg";

// SVG de services.
import SvgCafeteria from "./assets/images-services/cafeteria.svg";
import SvgCalendar from "./assets/images-services/calendar.svg";
import SvgIncapacities from "./assets/images-services/incapacities.svg";
import SvgPayroll from "./assets/images-services/payroll.svg";
import SvgRecords from "./assets/images-services/records.svg";
import SvgVacations from "./assets/images-services/vacations.svg";
import SvgVouchers from "./assets/images-services/vouchers.svg";
import SvgEye from "./assets/svgs/icon-eye.svg";
import SvgSend2 from "./assets/svgs/icon-send2.svg";

export const LogoToyota = ({ className }) => {
  return (
    <MyImage
      width="116px"
      height="46px"
      src={LogoToyotaImg}
      alt={"logo toyota"}
      className={className}
    />
  );
};

// Logo de Integrity.
export const LogoIntegrity = ({ className, width = "234px" }) => {
  return (
    <MyImage width={width} src={LogoIntegritySvg} alt={"logo integrity"} className={className} />
  );
};

export const ImgWorkersHero = ({ className }) => {
  return (
    <MyImage
      width={"700px"}
      height="auto"
      src={HeroWorkersImg}
      alt={"hero workers integrity"}
      className={className}
    />
  );
};

export const ImgSplash = ({ className }) => {
  const window = useWindowSize();
  return (
    <MyImage
      width={`${
        window.width > 700
          ? "674px"
          : window.width > 600
          ? "400px"
          : window.width > 400
          ? "300px"
          : "241px"
      }`}
      height="auto"
      src={SplashImg}
      alt={"splash tmmbc"}
      className={className}
    />
  );
};
export const ImgPerson = ({ className }) => {
  return (
    <MyImage
      width={"311px"}
      height="auto"
      src={PersonImg}
      alt={"person tmmbc"}
      className={className}
    />
  );
};
export const ImgCafeteria = ({ className, width = "537px" }) => {
  return (
    <MyImage
      width={width}
      height="auto"
      src={CafeteriaImg}
      alt={"person tmmbc"}
      className={className}
    />
  );
};

export const CloudsSvg = ({ className }) => {
  return (
    <MyImage width="100%" height="auto" src={SvgClouds} alt={"clouds"} className={className} />
  );
};

// Iconos de servicios.
export const IconCafeteria = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgCafeteria} width={width} height={height} className={className} />;
};
export const IconIncapacities = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgIncapacities} width={width} height={height} className={className} />;
};
export const IconCalendar = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgCalendar} width={width} height={height} className={className} />;
};
export const IconPayroll = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgPayroll} width={width} height={height} className={className} />;
};
export const IconRecords = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgRecords} width={width} height={height} className={className} />;
};
export const IconVacations = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgVacations} width={width} height={height} className={className} />;
};
export const IconVouchers = ({ className, width = "60px", height = "60px" }) => {
  return <MyImage src={SvgVouchers} width={width} height={height} className={className} />;
};

export const ConfigSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g id="Group_40581" data-name="Group 40581" transform="translate(-828 -35)">
        <circle
          id="Ellipse_289"
          data-name="Ellipse 289"
          cx="25"
          cy="25"
          r="25"
          transform="translate(828 35)"
          fill="#4b2192"
        />
        <g id="Group_40476" data-name="Group 40476" transform="translate(690.65 48.998)">
          <path
            id="Path_46706"
            data-name="Path 46706"
            d="M173.282,9.784a.716.716,0,0,0-.709-.541,2.42,2.42,0,0,1-1.664-4.227.611.611,0,0,0,.068-.834,11.089,11.089,0,0,0-1.745-1.76.614.614,0,0,0-.839.068,2.53,2.53,0,0,1-2.743.618A2.451,2.451,0,0,1,164.167.719a.616.616,0,0,0-.541-.645,11.092,11.092,0,0,0-2.474-.007.614.614,0,0,0-.546.627A2.444,2.444,0,0,1,159.1,3.04a2.554,2.554,0,0,1-2.725-.618.612.612,0,0,0-.83-.068,10.925,10.925,0,0,0-1.779,1.76.618.618,0,0,0,.064.838,2.435,2.435,0,0,1,.623,2.75,2.545,2.545,0,0,1-2.395,1.479.6.6,0,0,0-.634.541,10.81,10.81,0,0,0,0,2.5.708.708,0,0,0,.718.541,2.421,2.421,0,0,1,2.263,1.509,2.448,2.448,0,0,1-.614,2.719.611.611,0,0,0-.068.832,10.885,10.885,0,0,0,1.741,1.76.614.614,0,0,0,.839-.064,2.539,2.539,0,0,1,2.747-.623,2.436,2.436,0,0,1,1.486,2.387.616.616,0,0,0,.541.645,11.34,11.34,0,0,0,1.268.073,10.351,10.351,0,0,0,1.208-.068.614.614,0,0,0,.546-.631,2.44,2.44,0,0,1,1.5-2.345,2.541,2.541,0,0,1,2.718.627.612.612,0,0,0,.83.068,11.1,11.1,0,0,0,1.779-1.76.614.614,0,0,0-.064-.838,2.42,2.42,0,0,1,1.638-4.235h.136a.616.616,0,0,0,.645-.541A11.391,11.391,0,0,0,173.282,9.784Zm-10.915,4.909a3.668,3.668,0,1,1,3.669-3.668h0A3.673,3.673,0,0,1,162.367,14.693Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export const HamburgerSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" viewBox="0 0 40 25">
      <defs>
        <clipPath id="a">
          <rect width="40" height="25" fill="none" />
        </clipPath>
      </defs>
      <g clip-path="url(#a)">
        <g transform="translate(-311 -27)">
          <rect width="40" height="5" rx="2.5" transform="translate(311 27)" fill="#511797" />
        </g>
        <g transform="translate(-311 -17)">
          <rect width="40" height="5" rx="2.5" transform="translate(311 27)" fill="#511797" />
        </g>
        <g transform="translate(-311 -7)">
          <rect width="40" height="5" rx="2.5" transform="translate(311 27)" fill="#511797" />
        </g>
      </g>
    </svg>
  );
};

export const IconInfo = ({ width = "16", height = "16", color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      className="bi bi-info-circle-fill"
      viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </svg>
  );
};

export const IconUpdate = ({ color = "white" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12">
      <g id="Grupo_41185" data-name="Grupo 41185" transform="translate(-2184.441 -1490.724)">
        <path
          id="Trazado_46921"
          data-name="Trazado 46921"
          d="M2194.923,1511.321l0,0a4.68,4.68,0,0,1-3.045,1.121,4.733,4.733,0,0,1-4.728-4.728v-.017h.82a.627.627,0,0,0,.525-.96l-1.452-2.256a.622.622,0,0,0-.543-.273.593.593,0,0,0-.507.274l-1.452,2.255a.616.616,0,0,0-.018.634.629.629,0,0,0,.544.326h.82v.017a6.011,6.011,0,0,0,5.992,5.977,5.944,5.944,0,0,0,3.857-1.41.63.63,0,0,0,.074-.888A.643.643,0,0,0,2194.923,1511.321Z"
          transform="translate(0 -10.969)"
          fill={color}
        />
        <path
          id="Trazado_46922"
          data-name="Trazado 46922"
          d="M2214.181,1497.043a.628.628,0,0,0-.543-.326h-.82v-.017a5.989,5.989,0,0,0-9.849-4.566.593.593,0,0,0-.222.426.66.66,0,0,0,.146.459.591.591,0,0,0,.426.222.66.66,0,0,0,.462-.147,4.679,4.679,0,0,1,3.045-1.121,4.733,4.733,0,0,1,4.728,4.728v.016h-.82a.627.627,0,0,0-.525.96l1.451,2.253a.621.621,0,0,0,1.051,0l1.454-2.258A.647.647,0,0,0,2214.181,1497.043Z"
          transform="translate(-14.814 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconSelectArrow = ({ toggle, color = "#000000", width = "14", height = "8" }) => {
  return (
    <>
      {toggle ? (
        // <Img src={SelectArrowUpSvg} alt="config" className={className} />
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 8">
          <g transform="translate(186.503 217.088) rotate(180)">
            <path
              d="M180.518,216.693l5.672-5.747c.722-.728.118-1.857-1.017-1.857H173.83c-1.12,0-1.738,1.117-1.016,1.857l5.672,5.747A1.5,1.5,0,0,0,180.518,216.693Z"
              transform="translate(0 0)"
              fill={color}
            />
          </g>
        </svg>
      ) : (
        // <Img src={SelectArrowDownSvg} alt="config" className={className} />
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 8">
          <g transform="translate(-172.503 -209.088)">
            <path
              d="M180.518,216.693l5.672-5.747c.722-.728.118-1.857-1.017-1.857H173.83c-1.12,0-1.738,1.117-1.016,1.857l5.672,5.747A1.5,1.5,0,0,0,180.518,216.693Z"
              transform="translate(0 0)"
              fill={color}
            />
          </g>
        </svg>
      )}
    </>
  );
};
export const IconSelectSettlement = ({
  toggle,
  color = "#511797 ",
  width = "15",
  height = "9",
}) => {
  return (
    <>
      {!toggle ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 9">
          <g id="Grupo_41088" data-name="Grupo 41088" transform="translate(15 9) rotate(180)">
            <g id="Grupo_41086" data-name="Grupo 41086" transform="translate(0)">
              <path
                id="Trazado_46872"
                data-name="Trazado 46872"
                d="M10.411,9.971l7.182,7.053a.308.308,0,0,1,0,.441l-1.347,1.322a.322.322,0,0,1-.449,0l-5.611-5.51-5.611,5.51a.322.322,0,0,1-.449,0L2.779,17.465a.308.308,0,0,1,0-.441L9.961,9.971A.319.319,0,0,1,10.411,9.971Z"
                transform="translate(-2.686 -9.879)"
                fill={color}
                fill-rule="evenodd"
              />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          id="Grupo_41088"
          data-name="Grupo 41088"
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 15 9">
          <g id="Grupo_41086" data-name="Grupo 41086" transform="translate(0)">
            <path
              id="Trazado_46872"
              data-name="Trazado 46872"
              d="M10.411,9.971l7.182,7.053a.308.308,0,0,1,0,.441l-1.347,1.322a.322.322,0,0,1-.449,0l-5.611-5.51-5.611,5.51a.322.322,0,0,1-.449,0L2.779,17.465a.308.308,0,0,1,0-.441L9.961,9.971A.319.319,0,0,1,10.411,9.971Z"
              transform="translate(-2.686 -9.879)"
              fill={color}
              fill-rule="evenodd"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export const IconCheck = ({ color = "#000000", colorCheck = "#fff", size = "60" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 60 60">
      <g id="Grupo_39782" data-name="Grupo 39782" transform="translate(-741 -485)">
        <circle
          id="Elipse_275"
          data-name="Elipse 275"
          cx="30"
          cy="30"
          r="30"
          transform="translate(741 485)"
          fill={color}
        />
        <path
          id="Trazado_46703"
          data-name="Trazado 46703"
          d="M314.755,161.233a2.235,2.235,0,0,1,.014,3.212l-.014.013L293.836,184.9a2.352,2.352,0,0,1-1.65.659h-.139a2.341,2.341,0,0,1-1.673-.863l-9.3-11.358a2.235,2.235,0,0,1,.095-3.211,2.362,2.362,0,0,1,3.286.092,2.284,2.284,0,0,1,.245.3l7.67,9.359,19.082-18.649a2.363,2.363,0,0,1,3.287-.013Z"
          transform="translate(473.558 342.438)"
          fill={colorCheck}
        />
      </g>
    </svg>
  );
};

export const IconCheckValidate = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g id="Grupo_41723" data-name="Grupo 41723" transform="translate(-27.091 -28.211)">
          <path
            id="Trazado_47166"
            data-name="Trazado 47166"
            d="M39.091,34.211c-1.189,0-2.108-2.973-1.145-3.708-.963.735-3.368-1.1-3-2.292-.367,1.19-3.341,1.19-3.708,0,.367,1.19-2.038,3.027-3,2.292.963.735.043,3.708-1.145,3.708,1.189,0,2.108,2.973,1.145,3.708.963-.735,3.368,1.1,3,2.292.367-1.189,3.341-1.189,3.708,0-.367-1.189,2.038-3.027,3-2.292C36.983,37.184,37.9,34.211,39.091,34.211Zm-6.686,2.311-2.027-2.132,1-1.049,1.03,1.082,2.4-2.524,1,1.049Z"
          />
        </g>
      </svg>
    </>
  );
};

export const IconTransfer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <path
        id="Trazado_47163"
        data-name="Trazado 47163"
        d="M16.936,8.171l-4.8,2.922a.133.133,0,0,1-.2-.114V9.39h-6.8A.133.133,0,0,1,5,9.257H5v-2.4a.133.133,0,0,1,.133-.133h6.8V5.135a.133.133,0,0,1,.2-.114l4.8,2.922a.134.134,0,0,1,0,.228Zm-.069,4.442h-6.8V11.025a.133.133,0,0,0-.2-.114l-4.8,2.922a.134.134,0,0,0,0,.228l4.8,2.922a.133.133,0,0,0,.2-.114V15.28h6.8A.133.133,0,0,0,17,15.147h0v-2.4a.133.133,0,0,0-.133-.133Z"
        transform="translate(-5 -5.002)"
        fill="#fff"
      />
    </svg>
  );
};

export const IconError = ({ size = "46", color = "#e60010", fill="#fff"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 46 46">
      <g id="Grupo_41317" data-name="Grupo 41317" transform="translate(-776 -344)">
        <g id="Grupo_41256" data-name="Grupo 41256" transform="translate(776 344)">
          <path
            id="Trazado_46935"
            data-name="Trazado 46935"
            d="M26.75,3.75a23,23,0,1,0,23,23A23.026,23.026,0,0,0,26.75,3.75Z"
            transform="translate(-3.75 -3.75)"
            fill={color}
          />
        </g>
        <rect
          id="Rectángulo_21703"
          data-name="Rectángulo 21703"
          width="5"
          height="30"
          rx="2.5"
          transform="translate(808.339 354.626) rotate(45)"
          fill={fill}
        />
        <rect
          id="Rectángulo_21704"
          data-name="Rectángulo 21704"
          width="5.001"
          height="30"
          rx="2.5"
          transform="translate(811.874 375.839) rotate(135)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export const IconBack = ({
  colorArrow = "#4b2192",
  colorText = "text-purple10",
  text = "Regresar",
}) => {
  return (
    <div className="d-flex pointer">
      <svg
        id="Grupo_39784"
        data-name="Grupo 39784"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="27"
        viewBox="0 0 28 27">
        <path
          id="Trazado_46312"
          data-name="Trazado 46312"
          d="M455.037,336.066a2.073,2.073,0,0,1,.495-1.266l10.956-11.659a1.83,1.83,0,1,1,2.644,2.531l-8.027,8.553h20.106a1.841,1.841,0,0,1,0,3.682H461.105l8.027,8.553a1.83,1.83,0,1,1-2.644,2.531l-10.956-11.659a1.8,1.8,0,0,1-.495-1.266Z"
          transform="translate(-455.037 -322.566)"
          fill={colorArrow}
        />
      </svg>
      <MyP className={`font-weight-bold ml10 ${colorText}`} fontSize="18px">
        {text}
      </MyP>
    </div>
  );
};

export const IconBack2 = ({ to, width = "28", height = "27", color = "#fff" }) => {
  return (
    <Link to={to} className="d-flex align-content-center">
      <svg
        id="Grupo_39784"
        data-name="Grupo 39784"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 28 27">
        <path
          id="Trazado_46312"
          data-name="Trazado 46312"
          d="M455.037,336.066a2.073,2.073,0,0,1,.495-1.266l10.956-11.659a1.83,1.83,0,1,1,2.644,2.531l-8.027,8.553h20.106a1.841,1.841,0,0,1,0,3.682H461.105l8.027,8.553a1.83,1.83,0,1,1-2.644,2.531l-10.956-11.659a1.8,1.8,0,0,1-.495-1.266Z"
          transform="translate(-455.037 -322.566)"
          fill={color}
        />
      </svg>
    </Link>
  );
};

export const IconFilterArrow = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="14" viewBox="0 0 42 14">
        <g
          id="Grupo_39781"
          data-name="Grupo 39781"
          transform="translate(259.121 16.273) rotate(180)">
          <path
            id="Trazado_46312"
            data-name="Trazado 46312"
            d="M455.037,329.566a1.053,1.053,0,0,1,.266-.656l5.9-6.046a1,1,0,0,1,1.394-.031.921.921,0,0,1,.029,1.344l-4.322,4.435h37.749a.955.955,0,1,1,0,1.909H458.3l4.322,4.435a.924.924,0,0,1-.029,1.346,1,1,0,0,1-1.394-.034l-5.9-6.046a.915.915,0,0,1-.266-.656Z"
            transform="translate(-237.916 -320.293)"
            fill="#4b2192"
          />
        </g>
      </svg>
    </>
  );
};

export const IconSendEmail = ({ color = "#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 30 15">
      <g id="Grupo_40555" data-name="Grupo 40555" transform="translate(-165.888 -127.296)">
        <path
          id="Trazado_46760"
          data-name="Trazado 46760"
          d="M176.739,127.3a1.912,1.912,0,0,0-1.915,1.875v11.25a1.912,1.912,0,0,0,1.915,1.875h17.234a1.912,1.912,0,0,0,1.915-1.875v-11.25a1.912,1.912,0,0,0-1.915-1.875Zm0,1.875h17.234v11.25H176.739Zm-9.894.938a.938.938,0,1,0,0,1.875h6.383a.938.938,0,1,0,0-1.875Zm12.437.293a1.054,1.054,0,0,0-.908.635.947.947,0,0,0,.239.986l2.982,2.891-2.952,2.627a.962.962,0,0,0-.06,1.328,1.013,1.013,0,0,0,1.356.059l3.012-2.686.748.723a2.358,2.358,0,0,0,3.291,0l.758-.732,3.022,2.7a1.013,1.013,0,0,0,1.356-.059.962.962,0,0,0-.06-1.328l-2.952-2.627,2.982-2.891a.971.971,0,0,0,.01-1.338,1.022,1.022,0,0,0-1.366,0l-5.1,4.941a.644.644,0,0,1-.588.01l-5.086-4.951a1.068,1.068,0,0,0-.688-.283Zm-11.16,3.457a.938.938,0,1,0,0,1.875h5.106a.938.938,0,1,0,0-1.875Zm1.277,3.75a.938.938,0,1,0,0,1.875h3.83a.938.938,0,1,0,0-1.875Z"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconSearch = ({ color = "", size = "16.001" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16.001 16.001">
      <g id="Grupo_38089" data-name="Grupo 38089" transform="translate(-136.186 2.233)">
        <path
          id="Trazado_46333"
          data-name="Trazado 46333"
          d="M151.763,10.906l-3.5-3.548a6.464,6.464,0,0,0,.765-3.094,6.423,6.423,0,1,0-12.845,0,6.46,6.46,0,0,0,6.422,6.5h0a6.3,6.3,0,0,0,3.293-.928l3.456,3.5a1.405,1.405,0,0,0,2,.036l.035-.036.408-.413A1.456,1.456,0,0,0,151.763,10.906Zm-9.175-2.32a4.266,4.266,0,0,1-4.241-4.29,4.241,4.241,0,1,1,8.482-.01,4.266,4.266,0,0,1-4.22,4.3Z"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconDownload = ({ size, color }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 12 12">
        <path
          id="Trazado_46763"
          data-name="Trazado 46763"
          d="M258.695,31.137V25.508H256.95V31.2l-1.8-1.807-1.233,1.239,3.875,3.889,3.925-3.938-1.234-1.238Z"
          transform="translate(-251.812 -25.508)"
          fill={color}
        />
        <path
          id="Trazado_46764"
          data-name="Trazado 46764"
          d="M187.6,351.386v1.321h-8.507v-1.321h-1.747v3.072h12v-3.072Z"
          transform="translate(-177.345 -342.458)"
          fill={color}
        />
      </svg>
    </>
  );
};

export const IconArrowDown = ({ color = "#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.996" height="12" viewBox="0 0 11.996 12">
      <g id="Grupo_40547" data-name="Grupo 40547" transform="translate(13120 2813) rotate(-90)">
        <g id="Grupo_39784" data-name="Grupo 39784" transform="translate(2801 -13120)">
          <path
            id="Trazado_46312"
            data-name="Trazado 46312"
            d="M455.037,328.564a.94.94,0,0,1,.212-.562l4.7-5.18a.757.757,0,0,1,1.11-.027.831.831,0,0,1,.023,1.151l-3.44,3.8h8.617a.819.819,0,0,1,0,1.636h-8.617l3.44,3.8a.834.834,0,0,1-.023,1.154.759.759,0,0,1-1.11-.029l-4.7-5.18a.816.816,0,0,1-.212-.562Z"
            transform="translate(-455.037 -322.566)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export const IconArrowRight = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15">
        <g id="Grupo_41598" data-name="Grupo 41598" transform="translate(-9 -8)">
          <g id="Grupo_41596" data-name="Grupo 41596" transform="translate(18 8) rotate(90)">
            <g id="Grupo_41086" data-name="Grupo 41086">
              <path
                id="Trazado_46872"
                data-name="Trazado 46872"
                d="M7.725,8.907l7.182-7.053a.308.308,0,0,0,0-.441L13.56.091a.322.322,0,0,0-.449,0L7.5,5.6,1.889.091a.322.322,0,0,0-.449,0L.093,1.413a.308.308,0,0,0,0,.441L7.275,8.907A.319.319,0,0,0,7.725,8.907Z"
                fill="#511797"
                fill-rule="evenodd"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
export const IconArrowLeft = () => {
  return (
    <>
      <svg
        id="Grupo_41599"
        data-name="Grupo 41599"
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="15"
        viewBox="0 0 9 15">
        <g id="Grupo_41596" data-name="Grupo 41596" transform="translate(9 0) rotate(90)">
          <g id="Grupo_41086" data-name="Grupo 41086">
            <path
              id="Trazado_46872"
              data-name="Trazado 46872"
              d="M7.725.093l7.182,7.053a.308.308,0,0,1,0,.441L13.56,8.909a.322.322,0,0,1-.449,0L7.5,3.4,1.889,8.909a.322.322,0,0,1-.449,0L.093,7.587a.308.308,0,0,1,0-.441L7.275.093A.319.319,0,0,1,7.725.093Z"
              fill="#511797"
              fill-rule="evenodd"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export const IconEdit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="Grupo_41271" data-name="Grupo 41271" transform="translate(-19 -19)">
        <path
          id="Trazado_46927"
          data-name="Trazado 46927"
          d="M41.872,19a2.272,2.272,0,0,0-1.617.669l-4.146,4.148A3.758,3.758,0,0,0,35,26.493V28.16h1.667a3.757,3.757,0,0,0,2.674-1.109l4.15-4.146A2.288,2.288,0,0,0,41.872,19Z"
          transform="translate(-13.16)"
        />
        <path
          id="Trazado_46928"
          data-name="Trazado 46928"
          d="M47.45,26.964,43.3,31.112a2.573,2.573,0,0,1-1.832.759H41V31.4a2.572,2.572,0,0,1,.759-1.83l4.148-4.148a1.093,1.093,0,0,1,1.545,1.545Z"
          transform="translate(-17.965 -4.905)"
        />
        <path
          id="Trazado_46929"
          data-name="Trazado 46929"
          d="M21.125,34.2h6.954A2.125,2.125,0,0,0,30.2,32.079V28.988H29.044v3.091a.966.966,0,0,1-.966.966H21.125a.966.966,0,0,1-.966-.966V25.125a.966.966,0,0,1,.966-.966h3.091V23H21.125A2.125,2.125,0,0,0,19,25.125v6.954A2.125,2.125,0,0,0,21.125,34.2Z"
          transform="translate(0 -3.204)"
        />
      </g>
    </svg>
  );
};

export const IconArrows = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16">
        <g id="Grupo_41266" data-name="Grupo 41266" transform="translate(-1233 -877)">
          <g id="Grupo_41313" data-name="Grupo 41313">
            <g id="Grupo_21904" data-name="Grupo 21904" transform="translate(1233 887)">
              <g id="Grupo_13371" data-name="Grupo 13371" transform="translate(0 0)">
                <g id="Grupo_13370" data-name="Grupo 13370">
                  <path
                    id="Trazado_1383"
                    data-name="Trazado 1383"
                    d="M-30.5-33.995a.616.616,0,0,1-.432-.176l-4.889-4.8a.592.592,0,0,1,0-.849.62.62,0,0,1,.864,0l4.457,4.376,4.457-4.376a.62.62,0,0,1,.864,0,.592.592,0,0,1,0,.849l-4.889,4.8a.616.616,0,0,1-.432.176"
                    transform="translate(35.996 39.995)"
                  />
                </g>
              </g>
            </g>
            <g id="Grupo_21905" data-name="Grupo 21905" transform="translate(1244 883) rotate(180)">
              <g id="Grupo_13371-2" data-name="Grupo 13371" transform="translate(0 0)">
                <g id="Grupo_13370-2" data-name="Grupo 13370">
                  <path
                    id="Trazado_1383-2"
                    data-name="Trazado 1383"
                    d="M-30.5-33.995a.616.616,0,0,1-.432-.176l-4.889-4.8a.592.592,0,0,1,0-.849.62.62,0,0,1,.864,0l4.457,4.376,4.457-4.376a.62.62,0,0,1,.864,0,.592.592,0,0,1,0,.849l-4.889,4.8a.616.616,0,0,1-.432.176"
                    transform="translate(35.996 39.995)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export const CheckBoxIcon = ({ toggle, onClick, className }) => {
  return (
    <div onClick={onClick} className={`${!toggle && className} d-flex align-items-center`}>
      {toggle ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <g id="Grupo_41279" data-name="Grupo 41279" transform="translate(16650.254 22065.254)">
            <rect
              id="Rectángulo_19500"
              data-name="Rectángulo 19500"
              width="18"
              height="18"
              rx="4"
              transform="translate(-16650.254 -22065.254)"
              fill="#4dd200"
            />
            <path
              id="Trazado_46761"
              data-name="Trazado 46761"
              d="M-376.89,99.867l-6.686,6.59a1.11,1.11,0,0,1-.773.316h-.01a1.107,1.107,0,0,1-.777-.329L-388.509,103a1.068,1.068,0,0,1,.028-1.524,1.1,1.1,0,0,1,.759-.3,1.092,1.092,0,0,1,.787.329l2.6,2.657,5.9-5.815a1.094,1.094,0,0,1,.773-.316,1.094,1.094,0,0,1,.773.315A1.069,1.069,0,0,1-376.89,99.867Z"
              transform="translate(-16258.562 -22158.654)"
              fill="#fff"
            />
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <rect
            id="Rectángulo_14739"
            data-name="Rectángulo 14739"
            width="18"
            height="18"
            rx="4"
            fill="#fff"
          />
        </svg>
      )}
    </div>
  );
};

export const IconSheet = ({ width = "35", height = "42" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35 42">
      <g id="Grupo_41265" data-name="Grupo 41265" transform="translate(-7.88 -3.13)">
        <path
          id="Trazado_46926"
          data-name="Trazado 46926"
          d="M14.409,22.675a1.453,1.453,0,0,0-1.451,1.455V36.487a1.453,1.453,0,0,0,1.451,1.455H36.351A1.453,1.453,0,0,0,37.8,36.487V24.13a1.453,1.453,0,0,0-1.451-1.455Zm9.52,2.91v3.269H15.86V25.585ZM15.86,31.763h8.069v3.269H15.86Zm10.971,3.269V31.763H34.9v3.269ZM34.9,28.854H26.831V25.585H34.9ZM42.35,13.5,31.874,3.559a.19.19,0,0,0-.044-.036,1.517,1.517,0,0,0-.167-.138,1.365,1.365,0,0,0-.319-.167c-.065-.022-.138-.044-.2-.058h-.015a1.172,1.172,0,0,0-.276-.029H13.684a5.819,5.819,0,0,0-5.8,5.819V39.311a5.819,5.819,0,0,0,5.8,5.819H37.076a5.819,5.819,0,0,0,5.8-5.819v-24.7A1.443,1.443,0,0,0,42.35,13.5ZM39.978,39.311a2.906,2.906,0,0,1-2.9,2.91H13.684a2.906,2.906,0,0,1-2.9-2.91V8.949a2.906,2.906,0,0,1,2.9-2.91H29.4v8.576a1.457,1.457,0,0,0,1.451,1.455h9.127Z"
          transform="translate(0 0)"
          fill="#4b2192"
        />
      </g>
    </svg>
  );
};

export const IconUpload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="Grupo_40648" data-name="Grupo 40648" transform="translate(189.345 37.508) rotate(180)">
        <path
          id="Trazado_46763"
          data-name="Trazado 46763"
          d="M258.695,31.137V25.508H256.95V31.2l-1.8-1.807-1.233,1.239,3.875,3.889,3.925-3.938-1.234-1.238Z"
          transform="translate(-74.467)"
          fill="#333"
        />
        <path
          id="Trazado_46764"
          data-name="Trazado 46764"
          d="M187.6,351.386v1.321h-8.507v-1.321h-1.747v3.072h12v-3.072Z"
          transform="translate(0 -316.95)"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export const IconChecks = ({ colorCheck = "var(--pink70)" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
      <g id="Grupo_41278" data-name="Grupo 41278" transform="translate(-246 -268.001)">
        <g id="Grupo_41277" data-name="Grupo 41277" transform="translate(246 268.001)">
          <path
            id="Trazado_46930"
            data-name="Trazado 46930"
            d="M-252.088,23.624h-10.691v1.262h10.691a.648.648,0,0,1,.663.631v9.316h1.326V25.517A1.943,1.943,0,0,0-252.088,23.624Z"
            transform="translate(265.458 -20.976)"
          />
          <path
            id="Trazado_46931"
            data-name="Trazado 46931"
            d="M-240.136,11.624h-10.673v1.324h10.673a.662.662,0,0,1,.662.662v9.775h1.324V13.61A1.986,1.986,0,0,0-240.136,11.624Z"
            transform="translate(256.149 -11.624)"
          />
          <rect
            id="Rectángulo_21772"
            data-name="Rectángulo 21772"
            width="12.49"
            height="11.035"
            rx="2"
            transform="translate(0 4.965)"
          />
        </g>
        <path
          id="Trazado_46932"
          data-name="Trazado 46932"
          d="M-381.077,99.234l-4.338,4.324a.716.716,0,0,1-.5.207h-.006a.714.714,0,0,1-.5-.216l-2.189-2.261a.706.706,0,0,1,.018-1,.707.707,0,0,1,.492-.2.705.705,0,0,1,.511.216l1.687,1.743,3.828-3.815a.706.706,0,0,1,.5-.207.706.706,0,0,1,.5.207A.707.707,0,0,1-381.077,99.234Z"
          transform="translate(637.022 177.722)"
          fill={colorCheck}
        />
      </g>
    </svg>
  );
};

export const IconClip = ({ color = "white" }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12">
        <path
          id="Trazado_46923"
          data-name="Trazado 46923"
          d="M24.761,15.771c-.243-.236-.486-.458-.744-.724a.521.521,0,0,1-.121.148c-1.443,1.433-2.916,2.851-4.373,4.27a2.769,2.769,0,0,1-2.506.8,2.662,2.662,0,0,1-2.247-2.571,2.545,2.545,0,0,1,.835-1.95c1.686-1.64,3.356-3.28,5.041-4.905a1.722,1.722,0,0,1,2.946,1.093,1.688,1.688,0,0,1-.562,1.285c-1.078,1.064-2.171,2.113-3.25,3.176l-.82.8a.621.621,0,0,1-.759.118.647.647,0,0,1-.167-1.093L21.5,12.846a1.025,1.025,0,0,1,.167-.1L20.9,12a.053.053,0,0,1-.03.015c-1.215,1.182-2.445,2.364-3.644,3.561a1.458,1.458,0,0,0-.425,1.315,1.718,1.718,0,0,0,2.885.975c1.382-1.33,2.764-2.674,4.146-4.019a2.457,2.457,0,0,0,.79-1.669A2.572,2.572,0,0,0,23.41,9.743a2.706,2.706,0,0,0-1.5-.443,2.841,2.841,0,0,0-2.035.842c-1.64,1.64-3.31,3.236-4.95,4.846a3.554,3.554,0,0,0-1.048,3.531,3.68,3.68,0,0,0,4.039,2.763,3.845,3.845,0,0,0,2.354-1.108c1.458-1.433,2.931-2.851,4.389-4.27C24.685,15.86,24.731,15.815,24.761,15.771Z"
          transform="translate(-13.761 -9.3)"
          fill={color}
        />
      </svg>
    </>
  );
};

export const IconWarning = ({ color = "#4b2192" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
      <g id="Grupo_41251" data-name="Grupo 41251" transform="translate(-1.954 -3.115)">
        <path
          id="Trazado_46925"
          data-name="Trazado 46925"
          d="M15.79,13.318l-5.761-9.6a1.264,1.264,0,0,0-1.69-.444,1.215,1.215,0,0,0-.461.444l-5.761,9.6a1.155,1.155,0,0,0,.007,1.2,1.226,1.226,0,0,0,1.075.6H14.708a1.226,1.226,0,0,0,1.075-.6A1.155,1.155,0,0,0,15.79,13.318Zm-6.837.134a.944.944,0,1,1,.976-.946.961.961,0,0,1-.976.946Zm.584-2.525a.275.275,0,0,1-.106.153.249.249,0,0,1-.139.038H9.246a1.791,1.791,0,0,0-.584,0,.266.266,0,0,1-.186-.038.275.275,0,0,1-.106-.153,20.783,20.783,0,0,1-.392-2.9.976.976,0,0,1,1.951,0A20.782,20.782,0,0,1,9.538,10.927Z"
          transform="translate(0)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const IconErase = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.001" viewBox="0 0 12 14.001">
      <g id="Grupo_38195" data-name="Grupo 38195" transform="translate(-243.072 -28.8)">
        <g id="Grupo_38194" data-name="Grupo 38194" transform="translate(243.072 28.8)">
          <path
            id="Trazado_46339"
            data-name="Trazado 46339"
            d="M254.434,30.141h-4.159V30.01a1.2,1.2,0,1,0-2.406,0v.131H243.71a.642.642,0,0,0,0,1.283h10.725a.642.642,0,0,0,0-1.283Z"
            transform="translate(-243.072 -28.8)"
            fill="red"
          />
          <path
            id="Trazado_46340"
            data-name="Trazado 46340"
            d="M323.24,305.28h-9.787a.222.222,0,0,0-.217.248l1.115,8.967a1.3,1.3,0,0,0,1.274,1.137h5.444a1.286,1.286,0,0,0,1.274-1.137l1.115-8.967A.222.222,0,0,0,323.24,305.28Zm-6.906,8.457H316.3a.369.369,0,0,1-.376-.35l-.478-5.774a.385.385,0,1,1,.767-.073l.478,5.774A.436.436,0,0,1,316.334,313.737Zm2.4-.394a.391.391,0,1,1-.782,0v-5.774a.391.391,0,1,1,.782,0Zm2.041.029a.379.379,0,0,1-.376.35h-.029a.372.372,0,0,1-.348-.423l.463-5.76a.38.38,0,0,1,.42-.35.372.372,0,0,1,.347.423Z"
            transform="translate(-312.346 -301.632)"
            fill="red"
          />
        </g>
      </g>
    </svg>
  );
};

export const IconArrowClockWise = ({ color = "white" }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill={color}
        className="bi bi-arrow-clockwise"
        viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
        />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
      </svg>
    </>
  );
};

const LogoMyTMM = styled.div`
  color: #d31f24;
  font-size: ${(props) => (props.size ? props.size : "34pt")};
  margin: 0;
  height: inherit;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "0.929")};
`;

export const MyTMM = ({ company = "BC", size, lineHeight }) => {
  return (
    <>
      <LogoMyTMM className="" size={size}>
        My<span className="font-weight-bold">TMM{company}</span>{" "}
      </LogoMyTMM>
    </>
  );
};

const SubtitleMyTMM = styled.p`
  color: #4b2192;
  font-size: ${(props) => (props.size ? props.size : "80pt")};
  text-transform: uppercase;
  margin: 0;
`;

export const MyTMMSubtitle = ({
  marginLogo = "-10px 0 16px 0",
  displayLogo = "flex",
  heightLogo = "76.54px",
  maxWidth = "430px",
  sizeLogo = "81.80px",
  sizeSubtitle = "19.5px",
  heightSubtitle = "42.66px",
  lineHeight = "0.929",
}) => {
  return (
    <>
      <div className="">
        <SpecialContainer
          margin={marginLogo}
          display={displayLogo}
          height={heightLogo}
          maxWidth={maxWidth}
          width={maxWidth}
          className="justify-content-center ">
          <MyTMM size={sizeLogo} lineHeight={lineHeight}></MyTMM>
        </SpecialContainer>
        <SpecialContainer
          display="flex"
          className="bg-white justify-content-center rounded-pill"
          maxWidth={maxWidth}
          width={maxWidth}
          height={heightSubtitle}>
          <SubtitleMyTMM size={sizeSubtitle}>Atención personalizada al asociado</SubtitleMyTMM>
        </SpecialContainer>
      </div>
    </>
  );
};

export const LogoMyTMMBC = () => {
  const window = useWindowSize();
  return (
    <>
      {window.width > 600 && <MyTMMSubtitle />}
      {window.width < 601 && (
        <MyTMMSubtitle
          marginLogo={"0 0 10px 0"}
          heightLogo={"46.28px"}
          maxWidth={"251.75px"}
          sizeLogo={"49.5px"}
          lineHeight={"0.829"}
          sizeSubtitle={"12px"}
          heightSubtitle={"25.8px"}
        />
      )}
    </>
  );
};

export const IconEye = () => {
  return <img src={SvgEye} alt="icon eye" />;
};
export const IconSend2 = () => {
  return <img src={SvgSend2} alt="icon eye" />;
};
