import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Assets, Dropdown, StyledComponents } from "../components-config";

export const CardGroups = styled.div`
  background: var(--grey130);
  width: ${(props) => (props.width ? `${props.width}` : "200px")};
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
    color: black;
  }
  li:hover {
    background-color: var(--grey90);
    //border-radius: 10px;
  }
  li:first-child {
    border-radius: ${(props) =>
      props.borderRadius
        ? `${props.borderRadius}px ${props.borderRadius}px 0 0 `
        : "10px 10px 0 0"};
  }

  li:last-child {
    border: none;
    border-radius: ${(props) =>
      props.borderRadius
        ? ` 0 0 ${props.borderRadius}px ${props.borderRadius}px`
        : "0 0 10px 10px"};
  }
  li:only-child {
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px ` : "10px")};
  }
`;

export const SelectSupplier = ({
  dropdownTop,
  group,
  onChange,
  reducerValue,
  width,
  overflow = true,
  disabled = false,
}) => {
  //const integrityReducer = useSelector((state) => state.integrityReducer);
  //const { company_selected } = integrityReducer;
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    if (!disabled) {
      setDropdownOpen((prevState) => !prevState);
    }
  };
  const [value, setValue] = useState(reducerValue);
  const changeStateSelect = (item) => {
    setValue(item.razonSocial);
    toggle();
    onChange(item);
  };

  useEffect(() => {
    setValue(reducerValue);
  }, [reducerValue]);
  return (
    <>
      <Dropdown.LayoutDropDown
        maxWidthToggle="540px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0"
            className={`d-flex justify-content-between align-items-center bg-grey130 ${
              disabled ? "disabled" : ""
            }`}
            width={width}
            height="32px"
            padding="0 10px"
            borderRadius="40px">
            <StyledComponents.MyP fontSize="12px" className=" text-black" margin="0">
              {value}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        margin={""}>
        <StyledComponents.DivToRelative top={dropdownTop} zIndex="100">
          {overflow ? (
            <StyledComponents.OverFlowSelects height={"235px"}>
              <CardGroups width={width} disabled={true}>
                <ul>
                  {group.map((item) => {
                    return (
                      <li key={item.id} className="pointer" onClick={() => changeStateSelect(item)}>
                        {item.razonSocial}
                      </li>
                    );
                  })}
                </ul>
              </CardGroups>
            </StyledComponents.OverFlowSelects>
          ) : (
            <CardGroups width={width} disabled={true}>
              <ul>
                {group.map((item) => {
                  return (
                    <li key={item.id} className="pointer" onClick={() => changeStateSelect(item)}>
                      {item.razonSocial}
                    </li>
                  );
                })}
              </ul>
            </CardGroups>
          )}
        </StyledComponents.DivToRelative>
      </Dropdown.LayoutDropDown>
    </>
  );
};
