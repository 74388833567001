import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Assets, Layouts, Modals, StyledComponents } from "../components-config";
import { InputFile } from "./InputFile";
import { SelectSupplier } from "./SelectSupplier";
import * as digitalizationActions from "./store/digitalizationActions";

export const Information = () => {
  return (
    <>
      <Layouts.Info>
        <div>
          <StyledComponents.MyP fontSize="14px" className="font-weight-bold text-purple40">
            Hola, {localStorage.getItem("username")}.
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
            Seleccione el nombre del proveedor del cual desea procesar documentos. A continuación,
            elija el archivo PDF desde su equipo utilizando el botón ‘Examinar’. Finalmente,
            presione ‘Procesar’ para iniciar la digitalización.
          </StyledComponents.MyP>
        </div>
      </Layouts.Info>
    </>
  );
};

export const Form = () => {
  const dispatch = useDispatch();
  const digitalizationReducer = useSelector((state) => state.digitalizationReducer);
  const [step, setStep] = useState({
    stepOneIsComplete: false,
    stepOneIsSuccess: false,
    stepTwoIsComplete: false,
    stepTwoIsSuccess: false,
    stepThreeIsComplete: false,
    stepThreeIsSuccess: false,
  });
  const [fileNames, setFileNames] = useState([]);
  const [files, setFiles] = useState([]);
  const [isAlredyProcessed, setIsAlredyProcessed] = useState(false);
  const [supplier, setSupplier] = useState({});
  const [supplierOptionView, setSupplierOptionView] = useState("- Elija una opción -");
  const [resumen, setResumen] = useState({ isShow: false, message: "" });

  const {
    list_supliers,
    digitalization_action_success,
    api_actions: { cargando, isActionSuccess, error },
  } = digitalizationReducer;

  useEffect(() => {
    dispatch(digitalizationActions.digitalizationMethods({}, "GetDigitalizationSupliers"));
  }, []);

  useEffect(() => {
    if (isAlredyProcessed && isActionSuccess) {
      setResumen({ isShow: true, message: digitalization_action_success });
    }
  }, [isAlredyProcessed, isActionSuccess]);

  useEffect(() => {
    setResumen({
      isShow: digitalization_action_success ? true : false,
      message: digitalization_action_success,
    });
  }, [digitalization_action_success]);

  useEffect(() => {
    if (error.length > 0) {
      setResumen({ isShow: true, message: error.join(", ") });
    }
  }, [error]);

  function handlerChangeInputFile(info) {
    if (info.fileCount === 0) {
      setResumen({ isShow: false, message: "" });
      setFileNames([]);
      setFiles([]);
      setStep({
        ...step,
        stepTwoIsComplete: false,
      });
    } else if (info.totalSizeMB > 10 || info.fileCount > 5) {
      setFileNames(info.nameFiles);
      setStep({
        ...step,
        stepTwoIsComplete: true,
        stepTwoIsSuccess: false,
      });
      setResumen({ isShow: true, message: "Máximo 5 archivos o 10 MB a la vez." });
    } else {
      setResumen({ isShow: false, message: "" });
      setFileNames(info.nameFiles);
      setFiles(info.files);
      setStep({ ...step, stepTwoIsComplete: true, stepTwoIsSuccess: true }); // stepOneIsSuccess: true
    }
  }

  function handlerChangeSuplier(data) {
    if (data.id != "") {
      setStep({ ...step, stepOneIsComplete: true });
      setSupplier(data);
      setSupplierOptionView(data.razonSocial);
    } else {
      setFileNames([]);
      setStep({
        ...step,
        stepOneIsComplete: false,
        stepTwoIsComplete: false,
        stepThreeIsComplete: false,
      });
    }
  }

  function handleClickProcess(e) {
    const data = {
      email: localStorage.getItem("email"),
      files: files,
      supplier: supplier.id,
    };
    dispatch(digitalizationActions.digitalizationMethods(data, "DigitalizationTransfer")).then(
      (data) => {
        setIsAlredyProcessed(true);
        setStep({ ...step, stepThreeIsComplete: true });
      }
    );
  }

  function handlerOnClickProcessOther() {
    setStep({
      stepOneIsComplete: false,
      stepTwoIsComplete: false,
      stepThreeIsComplete: false,
    });
    setResumen({ ...resumen, isShow: false });
    setFileNames([]);
    setIsAlredyProcessed(false);
    setSupplierOptionView("- Elija una opción -");
  }

  return (
    <>
      {cargando && (
        <Modals.ModalLoading message="Un momento, por favor, el documento está siendo procesado…" />
      )}

      <Information />
      <Layouts.Card
        margin="4px 0 0 0"
        padding="40px 20px"
        borderRadius="0 0 20px 20px"
        className="text-purple40">
        <StyledComponents.SpecialContainer>
          {/* Step 01 */}
          <StyledComponents.MyP fontSize="14px" className="mb10 font-weight-bold text-purple40">
            1. Seleccionar el Proveedor
          </StyledComponents.MyP>

          <StyledComponents.SpecialContainer className="d-flex align-items-center justify-content-between">
            <SelectSupplier
              reducerValue={supplierOptionView}
              group={[{ id: "", razonSocial: "- Elija una opción -" }, ...list_supliers]}
              onChange={handlerChangeSuplier}
              width="540px"
              overflow={false}
              dropdownTop="35px"
              disabled={isAlredyProcessed && isActionSuccess}
            />
            {step.stepOneIsComplete && (
              <Assets.IconCheck color="var(--green50)" colorCheck="black" size="32" />
            )}
          </StyledComponents.SpecialContainer>

          <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
        </StyledComponents.SpecialContainer>

        {/* Step 02 */}
        <StyledComponents.SpecialContainer className={step.stepOneIsComplete ? "" : "disabled"}>
          <StyledComponents.MyP fontSize="14px" className="mb10 font-weight-bold text-purple40">
            2. Cargar Archivo PDF (Máximo 5 archivos o 10 MB a la vez)
          </StyledComponents.MyP>
          <StyledComponents.SpecialContainer className="d-flex align-items-center justify-content-between">
            <InputFile
              disabled={
                isAlredyProcessed && isActionSuccess ? true : step.stepOneIsComplete ? false : true
              }
              onChange={handlerChangeInputFile}
            />

            <StyledComponents.SpecialContainer
              className="d-flex align-items-center bg-grey130 mr80"
              gridGap="4px"
              fontSize="12px"
              style={{ color: "black" }}
              borderRadius="4px"
              padding="0px 8px"
              height="32px">
              <span>{fileNames.join(", ")}</span>
            </StyledComponents.SpecialContainer>

            <div style={{ width: "32px" }}>
              {step.stepTwoIsComplete &&
                (step.stepTwoIsSuccess ? (
                  <Assets.IconCheck color="var(--green50)" size="32px" colorCheck="black" />
                ) : (
                  <Assets.IconError color="var(--red70)" fill="black" size="32px" />
                ))}
            </div>
          </StyledComponents.SpecialContainer>
          <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
        </StyledComponents.SpecialContainer>
        {/* Step 03 */}
        <StyledComponents.SpecialContainer
          className={step.stepTwoIsComplete && step.stepTwoIsSuccess ? "" : "disabled"}>
          <StyledComponents.MyP fontSize="14px" className="mb10 font-weight-bold text-purple40">
            3. Procesar PDF para Digitalización
          </StyledComponents.MyP>
          <StyledComponents.SpecialContainer className="d-flex align-items-center justify-content-between">
            <StyledComponents.MyButton
              width="152px"
              height={"28px"}
              padding="0 20px"
              margin="0"
              className={`${
                isAlredyProcessed && isActionSuccess
                  ? "btn-disabled"
                  : step.stepTwoIsComplete
                  ? false
                  : "btn-disabled"
              } bg-green50 text-grey80 font-weight-bold`}
              disabled={
                isAlredyProcessed && isActionSuccess ? true : step.stepTwoIsComplete ? false : true
              }
              onClick={(e) => handleClickProcess(e)}>
              <span className="mr10">Procesar PDF</span>
              <Assets.IconUpload />
            </StyledComponents.MyButton>
            <div style={{ width: "32px" }}>
              {step.stepThreeIsComplete &&
                (isActionSuccess ? (
                  <Assets.IconCheck color="var(--green50)" size="32px" colorCheck="black" />
                ) : (
                  <Assets.IconError color="var(--red70)" fill="black" size="32px" />
                ))}
            </div>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
      <Container>
        {resumen.isShow && (
          <StyledComponents.SpecialContainer margin="0 20px">
            <StyledComponents.MyP
              fontSize="14px"
              className="mt40 font-weight-bold"
              style={{ color: "black" }}>
              Comentarios:
            </StyledComponents.MyP>
            {isAlredyProcessed && isActionSuccess ? (
              <div style={{ fontSize: "12px" }}>{resumen.message}</div>
            ) : (
              <div style={{ fontSize: "12px" }} className={`text-danger`}>
                {resumen.message}
              </div>
            )}
          </StyledComponents.SpecialContainer>
        )}
        {isAlredyProcessed && (
          <StyledComponents.MyButton
            maxWidth={"160px"}
            height={"28px"}
            className={` d-flex align-items-center justify-content-center mt20 bg-purple20 text-white font-weight-bold`}
            padding={"6px"}
            style={{ fontSize: "12px" }}
            onClick={handlerOnClickProcessOther}>
            <div className={"mr10"}>Procesar otro PDF </div>
            <Assets.IconArrowClockWise />
          </StyledComponents.MyButton>
        )}
      </Container>
    </>
  );
};
