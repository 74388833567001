import React, { useEffect, useRef, useState } from "react";
import { StyledComponents } from "../components-config";

export const InputFile = ({ disabled, onChange }) => {
  const isFirstRender = useRef(true);

  const [fileData, setFileData] = useState({
    files: [],
    nameFiles: [],
    fileCount: 0,
    totalSizeMB: 0,
  });

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const pdfFiles = selectedFiles.filter((file) => file.type === "application/pdf");

    const totalSize = pdfFiles.reduce((acc, file) => acc + file.size, 0) / (1024 * 1024);

    setFileData({
      files: pdfFiles,
      nameFiles: pdfFiles.map((file, index) => file.name),
      fileCount: pdfFiles.length,
      totalSizeMB: parseFloat(totalSize.toFixed(6)),
    });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      onChange(fileData);
    }
  }, [fileData]);

  return (
    <>
      <StyledComponents.MyLabel
        type="text"
        width="100px"
        height={"32px"}
        padding="0 20px"
        margin="0 10px 0 0"
        borderRadius="4px"
        fontSize="12px"
        className={`d-flex align-items-center bg-blue50 text-white font-weight-bold ${
          disabled ? "label-disable" : ""
        }`}
        htmlFor="upload-invoices">
        Examinar...
      </StyledComponents.MyLabel>
      <StyledComponents.MyInput
        type="file"
        multiple
        accept="application/pdf"
        className="input-file"
        id="upload-invoices"
        disabled={disabled}
        onChange={handleFileChange}></StyledComponents.MyInput>
    </>
  );
};
