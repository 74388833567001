import {
  DIGITALIZATION_CARGANDO,
  DIGITALIZATION_CHANGE_STATE,
  DIGITALIZATION_CLEAN_STATE,
  DIGITALIZATION_ERROR,
  DigitalizationTransfer,
  GetDigitalizationSupliers,
} from "./digitalizationTypes";

import { errorHandler } from "../../components-api/ConfigApi";

export const cfdisCleanState = () => (dispatch) => {
  dispatch({
    type: DIGITALIZATION_CLEAN_STATE,
  });
};

export const changeState = (variable, value) => (dispatch) => {
  dispatch({
    type: DIGITALIZATION_CHANGE_STATE,
    payload: {
      variable,
      value,
    },
  });
};

export const digitalizationMethods = (data, method) => async (dispatch) => {
  dispatch({
    type: DIGITALIZATION_CARGANDO,
  });
  try {
    let answer;
    switch (method) {
      case "GetDigitalizationSupliers":
        answer = await GetDigitalizationSupliers(data);
        break;
      case "DigitalizationTransfer":
        answer = await DigitalizationTransfer(data);
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: DIGITALIZATION_ERROR,
      payload: messageError,
    });
  }
};
