import axios from "axios";

export const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};
/*
 * Función para detectar el tipo de error.
 */
export const errorResponse = (data) => {
  if (data.ModelState) {
    return data.ModelState[""];
  }
  if (data.message) {
    return data.message;
  }
  if (data.error_description) {
    return data.error_description;
  }
  if (data.error) {
    return data.error;
  }
  if (data.code) {
    return data.message;
  }
  if (data.detail) {
    return data.detail;
  }
};

/*
 * Función para manejar errores.
 */
export const errorHandler = (error) => {
  let messageError = "";
  if (error.code === "ECONNABORTED") {
    return "Tiempo de espera agotado, vuelva a intentar.";
  }
  if (!error.response) {
    messageError = "Error de conexión";
  } else {
    switch (error.response.status) {
      case 400:
        messageError = errorResponse(error.response.data);
        break;
      case 404:
        messageError = errorResponse(error.response.data);
        break;
      case 406:
        messageError = errorResponse(error.response.data);
        break;
      case 500:
        if (error?.response?.data?.errors) {
          messageError = errorResponse({ error: error.response.data.errors });
        } else {
          messageError = errorResponse(error.response.data);
        }

        break;
      case 401:
        window.open(`/`, "_self");
        setLocalStorage();
        break;
      default:
        messageError = errorResponse(error.response.data);
        break;
    }
  }
  return messageError;
};

/*
 * Funcion para manejar el encabezado del token.
 */
function configHeader(token, timeOut = 10000000) {
  const tokenSave = localStorage.getItem("token");
  let config;
  if (token === true) {
    config = {
      timeout: timeOut,
      headers: {
        Authorization: `Token ${tokenSave}`,
        //Authorization: `Token f7dbab1d07b0d5e7ecbf2420bd3f6900bbcf57ac`,
        //"Content-Type": "application/json", application/x-www-form-urlencoded
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return config;
  }
  if (token === "json") {
    config = {
      timeout: timeOut,
      headers: {
        Authorization: `Token ${tokenSave}`,
        "Content-Type": "application/json",
        "x-app-cocia": localStorage.getItem("x-app-cocia"),
        //"Access-Control-Allow-Origin":"*"
      },
    };
    return config;
  }
  if (token === "company") {
    config = {
      timeout: timeOut,
      headers: {
        Authorization: `Token ${tokenSave}`,
        "Content-Type": "application/json",
        "x-app-cocia": localStorage.getItem("x-app-cocia"),
        //"Access-Control-Allow-Origin":"*"
      },
    };
    return config;
  }
  if (token === "company-form-data") {
    config = {
      timeout: timeOut,
      headers: {
        Authorization: `Token ${tokenSave}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-app-cocia": localStorage.getItem("x-app-cocia"),
      },
    };
    return config;
  }
  config = {
    timeout: timeOut,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return config;
}

/*
 * Función para setear local storage
 */
export const setLocalStorage = (respuesta) => {
  if (respuesta === undefined) {
    localStorage.removeItem("token");
    localStorage.removeItem("photo");
    localStorage.removeItem("time");
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("email");

    return;
  }

  let url = `data:image/png;base64,${respuesta.data.user_photo}`;
  localStorage.setItem("photo", url);
  localStorage.setItem("date", respuesta.data.date);
  localStorage.setItem("time", respuesta.data.time);
  localStorage.setItem("token", respuesta.data.token);
  localStorage.setItem("username", respuesta.data.user_fullname);
  localStorage.setItem("email", respuesta.data.user_email);

  return;
};

/*
 * Función para manejar las peticiones del api, reducir y optimizar código
 * uri: dirección que vamos a llamar del back
 * header: si requiere autenticación el usuario
 * apiMethod: get, post, put, delete
 * body: cuerpo si lo necesita
 */
export const axios_api = async (uri = "", header, apiMethod = "", body = {}, timeOut) => {
  let respuesta = {};

  if (uri === "") {
    return (respuesta = {
      data: "No se especifico ninguna uri",
    });
  }

  const config = configHeader(header, timeOut);

  const isFullUri = uri.substring(0, 4) === "http" ? true : false;

  let pathApi = "";

  if (isFullUri) {
    pathApi = uri;
  } else {
    pathApi = `${process.env.REACT_APP_ROUTE}${uri}`;
  }

  switch (apiMethod.toLowerCase()) {
    case "get":
      respuesta = await axios.get(pathApi, config);
      break;
    case "post":
      respuesta = await axios.post(pathApi, body, config);
      break;
    case "put":
      respuesta = await axios.put(pathApi, body, config);
      break;
    case "delete":
      respuesta = await axios.delete(pathApi, config);
      break;
    default:
      respuesta = {
        data: "No se envio ningun ApiMethod",
      };
      break;
  }

  return respuesta;
};

export const signOff = (e, href = "") => {
  e.preventDefault();
  setLocalStorage();
  window.open(href ? href : "/", "_self");
};
