import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root{

        --white: #ffffff;
        --grey190: #BAC0CE;
        --grey180: #F0EFEF;
        --grey170: #D3D7E3;
        --grey150: #747474;
        --grey140: #CECECE;
        --grey130: #F0F0F0;
        --grey120: #DEE3F0;
        --grey110: #E6E6E6;
        --grey100: #E1E3EB;
        --grey90: #F9F9FC;
        --grey80: #333333;
        --grey70:#9B9B9B;
        --grey60: #D3D9E8;
        --grey50: #EEF1F9;
        --grey30: #CDCECE;
        --grey10: #FAFAFA;
        --purple10: #511797;
        --purple20: #521798;
        --purple40: #4B2192;
        --purple50: #4b2790;
        --purple60: #9A00FF;
        --purple70: #4B1994;
        --purple80: #542294;
        --purple90: #DEB5FF;
        --purple100: #CA93FA;
        --purple110: #DFE4F2;
        --purple120: #F5EEF9;
        --red10: #E60010;
        --red70: #FF9999;
        --green10: #14FE41;
        --green20: #14FF41;
        --green30: #4DD200;
        --green40: #62D13E;
        --green50: #b8ffa1;
        --blue10: #0045FF;
        --blue20: #8CE8FF;
        --blue30: #42A5C9;
        --blue40: #A1E9FF;
        --blue50: #3C87FF;
        --pink10: #FF14C1;
        --pink20: #FF22BC;
        --pink30: #AA20FF;
        --pink40: #EAD6FF;
        --pink50: #9A00FF;
        --pink60: #DE2CD0;
        --pink70: #ED11AF;
        --pink80: #EA07AD;
        --yellow10: #FFF500;
        --yellow20: #FFF971;
    }

    body {
       font-family: "Ubuntu", sans-serif !important;
       background: white;
       overflow-x: hidden;
    }

    // Change default values bootstrap.

    .container{
        padding: 0;
    }
    // fix styles on overflow
    ::-webkit-scrollbar {
        width: 5px;
        height: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: transparent;
        border-radius: 5px;
        opacity: 0;

    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background:  transparent;
        border-radius: 5px;
        opacity: 0;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: transparent;
        opacity: 0;

    }
    // finish overflow styles
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {

        -webkit-appearance: none;
        margin: 0;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }
    input.middle:focus {
    outline-width: 0;
    }

    .pointer {
        cursor: pointer;
    }


    a:hover{
        text-decoration: none;
    }

    p{
        margin:0;
    }

    .modal-backdrop {
        background-color: var(--purple40) !important;
    }
    .modal {
        display: flex !important;
        align-items: center !important;
    }
    .modal-content {
        background-color: transparent!important;
        border: none!important;
        width: 900px;
    }
    .modal-dialog{
        margin: auto;
        width: 900px;
        display: flex;
        justify-content: center;
    }
    // Termina default changes bootstrap.

    // margins
    .mr5{
        margin-inline-end: 5px;
    }
    .ml5{
        margin-inline-start: 5px;
    }
    .ml8{
        margin-inline-start: 8px;
    }
    .ml10{
        margin-inline-start: 10px;
    }
    .ml15{
        margin-inline-start: 15px;
    }
    .mr10{
        margin-inline-end: 10px;
    }
    .mr15{
        margin-inline-end: 15px;
    }
    .ml20{
        margin-inline-start: 20px;
    }
    .mr20{
        margin-inline-end: 20px;
    }
    .mb10{
        margin-block-end: 10px;
    }
    .mb20{
        margin-block-end: 20px;
    }
    .mb25{
        margin-block-end: 25px;
    }
    .mb40{
        margin-block-end: 40px;
    }
    .mt20{
        margin-block-start: 20px;
    }
    .mt40{
        margin-block-start: 40px;
    }

    .mt80{
        margin-block-start: 80px;
    }
    .mr80{
        margin-inline-end: 80px;
    }

    .border-radius-collapse {
        border-radius: 20px
    }

    .align-content-evenly {
        align-content: space-evenly;
    }

    .h-70 {
        height: 70%;
    }

    // Layouts
    .vh20{
        height: 20vh;
    }
    //Terminan Layouts
    // estilos de bg

    .bg-white {
        background: var(--white);
    }
    .bg-green30 {
        background: var(--green30);
    }
    .bg-green40 {
        background: var(--green40);
    }
    .bg-green50 {
        background: var(--green50);
    }
    .bg-grey10 {
        background: var(--grey10)!important;
    }

    .bg-grey50{
        background: var(--grey50);
    }

    .bg-grey60 {
        background: var(--grey60);
    }

    .bg-grey110 {
        background: var(--grey110)
    }
    .bg-grey120 {
        background: var(--grey120)
    }
    .bg-grey130 {
        background: var(--grey130)
    }

    .bg-purple20{
        background: var(--purple20);
    }

    .bg-purple50{
        background:var(--purple50);
    }
    .bg-purple60{
        background:var(--purple60);
    }
    .bg-purple90{
        background:var(--purple90);
    }

    .bg-blue20{
        background: var(--blue20);
    }
    .bg-blue40{
        background: var(--blue40);
    }
    .bg-blue50{
        background: var(--blue50);
    }
    .bg-pink40{
        background: var(--pink40);
    }
    .bg-pink60{
        background: var(--pink60);
    }
    .bg-pink70{
        background: var(--pink70);
    }
    .bg-yellow10{
        background: var(--yellow10);
    }
    .bg-yellow20{
        background: var(--yellow20);
    }
    .bg-red70{
        background: var(--red70);
    }


    // terminan estilos de bg

    // estilos de textos
    .crop-text{
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .text-black{
        color: black;
    }
    .text-red10{
        color: var(--red10);
    }

    .text-purple10{
        color: var(--purple10);
    }

    .text-purple40{
        color: var(--purple40)
    }
    .text-grey30{
        color: var(--grey30);
    }
    .text-grey70{
        color: var(--grey70)
    }
    .text-grey80{
        color: var(--grey80)
    }
    .text-grey150{
        color: var(--grey150)
    }
    .text-green10{
        color: var(--green10)
    }
    .text-blue10{
        color: var(--blue10)
    }
    .text-blue30{
        color: var(--blue30)
    }
    .text-pink30{
        color: var(--pink30)
    }

    .text18{
        font-size:18px
    }
    // terminan estilos de textos
    .btn-disabled{
        opacity: 0.5;
        cursor: not-allowed!important;
    }

    .checked-border {
        border: 1px solid var(--grey190);
        border-radius: 4px;
    }
`;
